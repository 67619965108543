<template>
  <mdb-navbar color="white" light>
    <mdb-navbar-brand tag="a" to="/adm/dashboard" waves>
      Connexion Voyages
    </mdb-navbar-brand>
    <mdb-navbar-toggler>
      <mdb-navbar-nav right>
        <mdb-dropdown tag="li" class="nav-item">
          <mdb-dropdown-toggle tag="a" navLink color="white" slot="toggle" waves-fixed>{{this.$store.state.firstname}} {{this.$store.state.lastname}} </mdb-dropdown-toggle>
          <mdb-dropdown-menu>
            <mdb-dropdown-item v-on:click="logout">Déconnexion</mdb-dropdown-item>
          </mdb-dropdown-menu>
        </mdb-dropdown>
      </mdb-navbar-nav>
    </mdb-navbar-toggler>
  </mdb-navbar>
</template>

<script>
    import {
        mdbNavbar, mdbNavbarBrand, mdbNavbarNav, mdbNavbarToggler, mdbDropdown, mdbDropdownToggle, mdbDropdownMenu, mdbDropdownItem,
    } from 'mdbvue';

    import Auth from '../libs/Auth';

    export default {
        name: 'Adminheader',
        data() {
            return {
                username: '',
                showmyaccount: false,
            };
        },
        components: {
            mdbNavbar,
            mdbNavbarBrand,
            mdbNavbarNav,
            mdbNavbarToggler,
            mdbDropdown,
            mdbDropdownToggle,
            mdbDropdownMenu,
            mdbDropdownItem,
        },
        methods: {
            logout() {
                const auth = new Auth();
                auth.logout();
                this.$router.push({ name: 'Login' });
            },
        },
    };
</script>
