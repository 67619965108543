<template>
  <mdb-container>
    <adminheader></adminheader>
    <section class="mt-3">
      <mdb-row>
        <mdb-col xl="6" md="6" class="mb-4">
          <mdb-btn outline v-on:click="gotoadminlist" color="primary"><mdb-icon icon="arrow-left" class="mr-1"/> Retour à la liste</mdb-btn>
        </mdb-col>
      </mdb-row>
      <mdb-row>
        <mdb-col md="12">
          <mdb-card>
            <mdb-card-header color="orange accent-3">Edition d'un administrateur</mdb-card-header>
            <mdb-card-body>
              <mdb-alert color="danger" v-if="errormsg">
                {{errormsg}}
              </mdb-alert>
              <mdb-container>
                <mdb-row>
                  <mdb-col md="12">
                    <mdb-input label="Prénom" v-model="admin.firstname" required />
                  </mdb-col>
                </mdb-row>
                <mdb-row>
                  <mdb-col md="12">
                    <mdb-input label="Nom" v-model="admin.lastname" required />
                  </mdb-col>
                </mdb-row>
                <mdb-row>
                  <mdb-col md="12">
                    <mdb-input label="Courriel" type="text" v-model="admin.email" required />
                  </mdb-col>
                </mdb-row>
                <mdb-row class="mt-3">
                  <mdb-col md="12">
                    <h2>Nouveau mot de passe</h2>
                  </mdb-col>
                </mdb-row>
                <mdb-row>
                  <mdb-col md="12">
                    <mdb-input label="Mot de passe" type="password" v-model="admin.pwd" required />
                  </mdb-col>
                </mdb-row>
                <mdb-row>
                  <mdb-col md="12">
                    <mdb-input label="Mot de passe (confirmation)" type="password" v-model="admin.pwd2" required />
                  </mdb-col>
                </mdb-row>
                <mdb-btn color="orange accent-3" v-if="isSaving===false" v-on:click="saveinfos()">Sauvegarder</mdb-btn>
              </mdb-container>
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
      </mdb-row>
    </section>
  </mdb-container>
</template>

<script>
    import Vue from 'vue';
    import Adminheader from '@/components/Adminheader.vue';

    import {
        mdbContainer,
        mdbRow,
        mdbCol,
        mdbBtn,
        mdbCard,
        mdbCardHeader,
        mdbCardBody,
        mdbInput,
        mdbAlert,
        mdbIcon,
    } from 'mdbvue';
    import Auth from '../libs/Auth';
    import Apicall from '../libs/Apicall';

    export default Vue.extend({
        name: 'Dashboard',
        components: {
            Adminheader,
            mdbContainer,
            mdbRow,
            mdbCol,
            mdbBtn,
            mdbCard,
            mdbCardHeader,
            mdbCardBody,
            mdbInput,
            mdbAlert,
            mdbIcon,
        },
        data() {
            return {
                admin: {
                    id: '',
                    firstname: '',
                    lastname: '',
                    email: '',
                    pwd: '',
                    pwd2: '',
                },
                isSaving: false,
                errormsg: '',
            };
        },
        mounted() {
            Auth.validateIfCanAccess('ADMINISTRATOR', 'Dashboard');
            this.loadAndDisplayAdmin();
        },
        methods: {
            loadAndDisplayAdmin() {
                const self = this;
                const api = new Apicall();
                api.getOneAdmin(this.$route.params.adminid).then((response) => {
                    if (response.status === 'OK') {
                        Object.keys(response.data.admin).forEach((key) => {
                            self.admin[key] = response.data.admin[key];
                        });
                    } else {
                        self.$router.push({ name: 'Adminlist' });
                    }
                });
            },
            saveinfos() {
                this.isSaving = true;
                const self = this;
                const api = new Apicall();
                api.editAdministrateur(this.admin).then((response) => {
                    if (response.status === 'OK') {
                        self.$router.push({ name: 'Adminlist' });
                    } else {
                        self.errormsg = response.msg;
                    }
                    self.isSaving = false;
                });
            },
            gotoadminlist() {
                this.$router.push({ name: 'Adminlist' });
            },
        },
    });
</script>
